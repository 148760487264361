

































































import Vue from "vue";
import { mapGetters } from "vuex";
import { timeAgo, formatDate } from "@/utils/helpers";
import { DateTime } from "luxon";
import { HOME } from "../../routes/endpoints";

enum IReportStatus {
    DRAFT = 0,
    SIGNED = 12,
    FINALISED = 6,
    PROCESSING = 7,
    AWAITING_SIGNING = 3,
    REGULATOR_APPROVAL_IN_PROGRESS = 5,
}

enum IReportRegulatorApprovalStatus {
    PENDING = "Pending",
    REVIEW_IN_PROGRESS = "ReviewInProgress",
}

export default Vue.extend({
    props: {
        "report": { type: Object, required: true },
        "show-actions": { type: Boolean, default: true },
        "textClass": { type: String, required: false, default: "border border-gray-light pointer" },
        "bottomClass": { type: String, required: false, default: "bg-gray-10" },
        "message": { type: Boolean, default: false, required: false },
        "showActions": { type: Boolean, default: false, required: false },
    },
    data() {
        return {
            HOME,
            IReportStatus,
            IReportRegulatorApprovalStatus,
            DateTime,
            isDeleting: false,
            isLoading: false,
            previewText: "",
            showUserInfo: false,
        };
    },
    computed: {
        ...mapGetters({
            profileData: "auth/getUserData",
        }),
        itemOptions() {
            const userIsAdmin = this.profileData.roles.includes("Admin");
            const userIsReviewerOfReport = this.profileData.roles.includes("Reviewer") && this.report.currentDeskUser.email === this.profileData.email;

            const val: any[] = [
                { text: "View Report", action: "view-report" },
                { text: "Report History", action: "view-report-history" },
            ];
            if (this.report.regulatorApprovalStatus === IReportRegulatorApprovalStatus.PENDING && userIsReviewerOfReport) {
                val.splice(1, 0, { text: "Start Review", action: "start-review" });
            } else if (this.report.regulatorApprovalStatus === IReportRegulatorApprovalStatus.REVIEW_IN_PROGRESS && userIsReviewerOfReport) {
                val.splice(1, 0, { text: "Continue Review", action: "view-report" });
            }

            if (this.report.regulatorApprovalStatus === IReportRegulatorApprovalStatus.PENDING && userIsAdmin) {
                val.splice(val.length === 3 ? 2 : 1, 0, { text: "Reassign Reviewer", action: "reassign-reviewer" });
            }

            return val;
        },
        dateLabel() {
            let res = "Date";
            if (this.report.reportStatus === IReportStatus.DRAFT) res = "Date Created";
            else if (this.report.reportStatus === IReportStatus.SIGNED) res = "Date Submitted Internally";
            else if (this.report.reportStatus === IReportStatus.FINALISED) res = "Date Submitted To Regulator";
            return res;
        },
        dateValue() {
            let res = "Date";
            if (this.report.reportStatus === IReportStatus.DRAFT) res = formatDate(this.report.dateCreated, DateTime.DATE_SHORT);
            else if (this.report.reportStatus === IReportStatus.SIGNED) res = formatDate(this.report.dateSubmittedInternally, DateTime.DATE_SHORT);
            else if (this.report.reportStatus === IReportStatus.FINALISED) res = formatDate(this.report.dateSentToRegulator, DateTime.DATE_SHORT);
            return formatDate(this.report.dateCreated, DateTime.DATETIME_SHORT);
        },
    },
    methods: {
        timeAgo,
        formatDate,

        performAction(action: string) {
            if (action === "view-report") {
                this.$router.push({ name: HOME.VIEW_REPORT.NAME, params: { id: this.report.id } });
            } else if (action === "view-report-history") {
                this.$router.push({ name: HOME.VIEW_REPORT_HISTORY.NAME, params: { id: this.report.id } });
            } else {
                this.$emit(action, this.report.id);
            }
        },
    },
});
